<template>
  <div class="wd-page" :class="{'wd-page-wap': clientType === 'wap'}">
    <div class="content-top">
      <!-- <img :src="titleImg"> -->
      <fm-title style="flex: 1;" title-text="我的考核" :need-border="false" :note-text="noteText">
        <slot name="title"></slot>
      </fm-title>
    </div>
  <div class="auth-user-page">
    <div class="a-item" :class="{'a-item-wap': clientType === 'wap'}" v-for="item in dataList" @click="go(item)" :key="item.id">
      <img :src="imgMap[item.appraisalId]" v-if="imgMap[item.appraisalId]" class="a-img"/>
      <div class="a-img" v-else>未选择图片</div>
      <div class="title">
        {{item.appraisalTitle}}({{item.appraisalTypeText}})
      </div>
      <div class="node">
        {{item.appraisalRemark}}
      </div>
      <div class="do-what">
        {{item.appraisalGroupTypeText}}
      </div>
    </div>
  </div>
  </div>
</template>

<script>

import {
  appraisalGroupDetailRequest as request
} from '../../api'

import {
  fileRequest
} from '@/api'

export default {
  computed: {
    noteText () {
      return '总数:' + this.dataList.length
    },
    statusList () {
      return this.$store.getters.appraisalStatusList
    },
    typeList () {
      return this.$store.getters.appraisalTypeList
    },
    groupTypeList () {
      return this.$store.getters.appraisalGroupTypeList
    },
    clientType () {
      return this.$store.getters.clientType
    }
  },
  created () {
    if (this.$authFunsProxy.base) {
      this.loadData()
    }
  },
  methods: {
    go (item) {
      if (item.appraisalGroupType == 'host') {
        this.$router.push({name: 'hrms.appraisal.doing', query: {id: item.appraisalId}})
      } else if (item.appraisalGroupType == 'check') {
        this.$router.push({name: 'hrms.appraisal.' + (item.appraisalType === 'scoring' ? 'checking' : item.appraisalType), query: {id: item.appraisalId}})
      } else if (item.appraisalGroupType == 'screen') {
        this.$router.push({name: 'hrms.screen', query: {id: item.appraisalId}})
      }
      // if (this.clientType !== 'wap') {
      //   if (item.appraisalGroupType == 'host') {
      //     this.$router.push({name: 'hrms.appraisal.doing', query: {id: item.appraisalId}})
      //   } else if (item.appraisalGroupType == 'check') {
      //     this.$router.push({name: 'hrms.appraisal.' + (item.appraisalType === 'scoring' ? 'checking' : item.appraisalType), query: {id: item.appraisalId}})
      //   } else if (item.appraisalGroupType == 'screen') {
      //     this.$router.push({name: 'hrms.screen', query: {id: item.appraisalId}})
      //   }
      // } else {
      //   if (item.appraisalGroupType == 'check') {
      //     this.$router.push({name: 'wap.' + (item.appraisalType === 'scoring' ? 'checking' : item.appraisalType), query: {id: item.appraisalId}})
      //   } else {
      //     this.$notice.info({
      //       title: '系统提示',
      //       desc: '请使用电脑打开'
      //     })
      //   }
      // }
    },
    async loadImg (id, fileId) {
      try {
        if (fileId) {
          const fileData = await fileRequest.download({
            id: fileId
          })
          let img = await new Promise(resolve => {
            let reader = new FileReader()
            reader.readAsDataURL(fileData)
            reader.onload = () => resolve(reader.result)
          })
          this.$set(this.imgMap, id, img)
        }
      } catch (e) {
        console.log(e)
      }
    },
    loadData () {
      this.loading = true
      let parm = {
        appraisalStatus: 'doing'
      }
      request.getMy(parm).then((data) => {
        data.forEach((v) => {
          v.appraisalStatusText = (this.statusList.find(v1 => v1.key === v.appraisalStatus) || {label: ''}).label
          v.appraisalTypeText = (this.typeList.find(v1 => v1.key === v.appraisalType) || {label: ''}).label
          v.appraisalGroupTypeText = (this.groupTypeList.find(v1 => v1.key === v.appraisalGroupType) || {label: ''}).label
          this.loadImg(v.appraisalId, v.myImgId)
        })
        this.dataList = data.filter(v => !['becheck', 'other'].includes(v.appraisalGroupType))
        this.loading = false
      }).catch((e) => {
        console.log(e)
        this.loading = false
      })
    }
  },
  data () {
    return {
      // titleImg: 'static/images/check/wd-a.png',
      chooseItem: null,
      imgMap: {},
      dataList: [],
      loading: true,
      chooseData: null,
      openDialog: false
    }
  }
}
</script>

<style lang="less" scoped>
.auth-user-page {
  margin-top: 10px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.content-top {
  display: flex;
  img {
    height: 24px;
    transform: translate(0px,0.6rem);
    margin-left: 14px;
  }
}
.wd-page {
  background: #fff;
  overflow-y: auto;
  margin: 20px;
}
.wd-page-wap {
  overflow-y: auto;
  margin: 0;
  -webkit-overflow-scrolling: touch;
  padding-top: 10px;
}
.a-item {
  width: calc(25% - 24px);
  // height: 294px;
  background: #E9E9E9;
  border: 1px solid #E9E9E9;
  background: #fff;
  border-radius: 8px;
  position: relative;
  margin: 0 6px 20px 14px;
  .a-img {
    height: 166px;
    width: 100%;
    background: #eee;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    color: #ddd;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  .title {
    font-size: 16px;
    color: rgba(0,0,0,0.85);
    margin-left: 24px;
    line-height: 22px;
    margin: 23px 24px 10px;
  }
  .node {
    color: rgba(0,0,0,0.45);
    margin-left: 24px;
    font-size: 14px;
  }
  .do-what {
    // position: absolute;
    // bottom: 23px;
    // left: 24px;
    padding-left: 24px;
    padding: 10px 24px 10px 24px;
    color: #707070;
    line-height: 20px;
    display: flex;
    // justify-content: flex-end;
  }
  .go-check {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(0,0,0,0.1);
    display: flex;
    font-size: 5rem;
    color: #2F5BEA;
    font-weight: 800;
    justify-content: center;
    align-items: center;
  }
}
.a-item-wap {
  width: calc(100% - 12px);
  margin: 0 10px 20px;
}
</style>